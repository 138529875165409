import React from "react";
import { Link } from "react-router-dom";
import Brand from "../../components/Common/Brand";
import BrandCloud from "./BrandCloud";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleService from "../../components/Service/SingleService";
import SinglePricing from "../../components/Pricing/SinglePricing";
import ProgressBar from "../../components/Elements/Progress/ProgressBar";
import ScrollToTop from "../../components/Common/ScrollTop";

// Service Image
import serviceIcon1 from "../../assets/img/service/style7/1.png";
import serviceIcon2 from "../../assets/img/service/style7/2.png";
import serviceIcon3 from "../../assets/img/service/style7/3.png";

import aboutImg1 from "../../assets/img/service/style12/cloudservice.png";

// Pricing Image
import silverImg from "../../assets/img/pricing/1.png";
import goldImg from "../../assets/img/pricing/2.png";
import platinumImg from "../../assets/img/pricing/3.png";

// Choose Us Image
import whyChooseUsImg from "../../assets/img/choose/3.png";
import { useTranslation } from 'react-i18next';

const ServiceDetailsContent = () => {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle={t('services.cloud.title')}
                titleClass="title"
                title={t('services.cloud.header')}
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4"
                descClass="desc desc-big"
                description=""
                secondDescClass="desc"
                secondDescription={
                  <React.Fragment>
                    <p>
                      {t('services.cloud.text.part_1')}
                    </p>
                    <p>
                      {t('services.cloud.text.part_2')}
                    </p>
                    <p>
                      {t('services.cloud.text.part_3')}
                    </p>
                    <ul className="features-list mb-5">
                      {t('services.cloud.text.part_4')}
                      <li className="mt-3">
                        <i className="fa fa-check mr-5"></i>
                        <span>Azure</span>
                      </li>
                      <li>
                        <i className="fa fa-check mr-5"></i>
                        <span>GCP</span>
                      </li>
                      <li>
                        <i className="fa fa-check mr-5"></i>
                        <span>AWS</span>
                      </li>
                    </ul>
                  </React.Fragment>
                }
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact">
                  <a className="readon learn-more">{t('contact_header.subtitle')}</a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="images">
                <img style={{ borderRadius: "50%" }} src={aboutImg1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* brand-area-start */}
      <BrandCloud />
      {/* brand-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default ServiceDetailsContent;

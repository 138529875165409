import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../../assets/img/logo/logo.png";
import edLogo from "../../../assets/img/logo/edtechlogo.png";
import { useTranslation } from 'react-i18next';

const TopHeader = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="topbar-area hidden-md">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="logo-part">
              <Link to="/">
                <img src={edLogo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 text-right">
            <ul className="rs-contact-info">
              <li className="contact-part">
                <i className="flaticon-location"></i>
                <span className="contact-info">
                  <span>{t('contact_cta.adress')}</span>
                  Opal tower Office 309 , Business Bay Dubai
                </span>
              </li>
              <li className="contact-part">
                <i className="flaticon-email"></i>
                <span className="contact-info">
                  <span>{t('contact_cta.email')}</span>
                  <a href="mailto:contact@bdtechnology.io">
                    {" "}
                    contact@bdtechnology.io
                  </a>
                </span>
              </li>
              <li className="contact-part">
                <i className="flaticon-call"></i>
                <span className="contact-info">
                  <span className="mb-2">{t('contact_cta.tel')}</span>
                  <a href="tel:+33 (7) 77 25 21 10"> +33 (7) 77 25 21 10</a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;

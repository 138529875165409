import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';
import SecurityDetails from './SecurityDetails';

// Breadcrumbs Image
import securityBg from '../../assets/img/bg/services/security.png';
import { useTranslation } from 'react-i18next';

const AnalyticSolutions = () => {
	const { t, i18n } = useTranslation();
	return (
		<React.Fragment>
			<Header
				parentMenu="service"
				secondParentMenu="services"
				activeMenu="/securité-solution"
			/>
			{/* breadcrumb-area-start */}
			<SiteBreadcrumb
				breadcrumbsImg={securityBg}
				pageTitle={t('services.security.title')}
				pageCategory="Services"
				pageName={t('services.security.title')}
			/>
			{/* breadcrumb-area-end */}
			<SecurityDetails/>
			<SearchModal/>
		</React.Fragment>
	);
};

export default AnalyticSolutions;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import edLogo from "./assets/img/logo/edtechlogo.png";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-owl-carousel2/src/owl.carousel.css';
import './assets/scss/App.scss';

const Loader = () => (
	<div className="App d-flex align-items-center vh-100 justify-content-center">
		<div className="justify-content-center">
			<img src={edLogo} className="App-logo"  alt="logo"/>
			<div className="text-center mt-5">loading...</div>
		</div>
	</div>
);

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<React.Suspense fallback={<Loader/>}>
				<App/>
			</React.Suspense>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 
import OwlCarousel from "react-owl-carousel2";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonial4 from "../../components/Testimonial/SingleTestimonial4";

// Service Image Icons
import serviceIcon1 from "../../assets/img/service/style11/1.png";
import serviceIcon2 from "../../assets/img/service/style11/2.png";
import serviceIcon3 from "../../assets/img/service/style11/3.png";
import serviceIcon4 from "../../assets/img/service/style11/4.png";

// WhyChooseUs Images

import brand1 from "../../assets/img/brand/style3/brand1.png";
import brand2 from "../../assets/img/brand/style3/brand2.png";
import brand3 from "../../assets/img/brand/style3/brand3.png";
import brand4 from "../../assets/img/brand/style3/brand4.png";
import brand5 from "../../assets/img/brand/style3/brand5.png";
import brand6 from "../../assets/img/brand/style3/brand6.png";
import brand7 from "../../assets/img/brand/style3/brand7.png";
import brand8 from "../../assets/img/brand/style3/brand8.png";
import brand9 from "../../assets/img/brand/style3/brand9.png";
import brand10 from "../../assets/img/brand/style3/brand10.png";
import brand11 from "../../assets/img/brand/style3/brand11.png";
import brand13 from "../../assets/img/brand/style3/brand13.png";
import brand14 from "../../assets/img/brand/style3/brand14.png";

import bgImg from "../../assets/img/bg/index-bg.jpg";

import "../../assets/style.css";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Technology = () => {
  const { t, i18n } = useTranslation();
  const options = {
    items: 3,
    nav: true,
    dots: false,
    margin: 50,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    navText: [
      "<i class='fa fa-angle-left techicon'></i>",
      "<i class='fa fa-angle-right techicon'></i>",
    ],
    loop: true,
    center: true,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        nav: false,
      },
      768: {
        items: 1,
        stagePadding: 0,
        nav: false,
      },
      992: {
        items: 1,
        stagePadding: 0,
        nav: true,
      },
      1200: {
        items: 1,
        stagePadding: 0,
      },
      1500: {
        items: 1,
        stagePadding: 0,
      },
    },
  };
  return (
    <div
      className="rs-technology style2 pt-110 pb-70 md-pt-74 md-pb-80"
      style={bgStyle}
      id="technology"
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44"
          subtitleClass="sub-text white-color"
          subtitle=""
          titleClass="title title2 white-color"
          title={t('partners_title')}
        />
        <div className="row align-items-center">
        </div>
        <div className="rs-testimonial style10">
          <OwlCarousel options={options}>
            <div className="row my-5 justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                VPN, SDWAN, Optimisation WAN, QoS
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand10} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Citrix</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand3} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Fortinet</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand4} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Paloalto</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand1} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Pulse Secure</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand5} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Cisco meraki</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Firewalls, Anti-ADT, Anti-DDOS
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand3} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Fortinet</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand4} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Paloalto</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5 justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Proxies, CASB
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand8} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">zscaler </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand9} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Squid</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-5 justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                LAN, WAN, WIFI
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand7} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">NETGEAR</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand3} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Fortinet</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand2} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Cisco</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand5} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Cisco meraki</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5 justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Sécurisation interne, NAC
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand3} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Fortinet</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand2} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Cisco</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand5} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Cisco meraki</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5 justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                IPAM, DNS, DHCP
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand11} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Infoblox</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5 justify-content-center">
              <span
                style={{
                  color: "white",
                  marginBottom: 20,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                MONITORING
              </span>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand14} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Solarwinds</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
                <div className="technology-wrap">
                  <div className="rs-grid-figure">
                    <div className="logo-img">
                      <a href="#">
                        <img className="" src={brand13} alt="hover-image" />
                      </a>
                    </div>
                  </div>
                  <div className="logo-title">
                    <h4 className="title">Centreon</h4>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default Technology;

import React from "react";
import { useTranslation } from 'react-i18next';

const ContactForm = (props) => {
  const { submitBtnClass } = props;
  const { t, i18n } = useTranslation();
  return (
    <form id="contact-form" action="#">
      <div className="row">
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="name"
            name="name"
            placeholder={t('contact_form.name')}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="email"
            name="email"
            placeholder={t('contact_form.email')}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="phone"
            name="phone"
            placeholder={t('contact_form.tel')}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="website"
            name="website"
            placeholder={t('contact_form.company')}
            required
          />
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="message"
            name="message"
            placeholder={t('contact_form.message')}
            required
          ></textarea>
        </div>
      </div>
      <div className="btn-part">
        <button
          className={
            submitBtnClass ? submitBtnClass : "readon learn-more submit"
          }
          type="submit"
        >
          {t('contact_header.btn')}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;

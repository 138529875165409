import React from 'react';
import SectionTitle from '../Common/SectionTitle';
import { useTranslation } from 'react-i18next';

const ContactInfo = (props) => {
	const { contactBoxClass } = props;
	const { t, i18n } = useTranslation();
	return (
		<div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
			<SectionTitle
				sectionClass="sec-title mb-44"
				subtitleClass="sub-text new-text white-color"
				subtitle={t('contact_cta.subtitle')}
				titleClass="title white-color"
				title={t('contact_cta.title')}
			/>
			<div className="address-box mb-24">
				<div className="address-icon">
					<i className="fa fa-phone"></i>
				</div>
				<div className="address-text">
					<span className="label">{t('contact_cta.tel')}:</span>
					<a href="tel:+33 (7) 77 25 21 10" className="d-block">
						{' '}
						+33 (7) 77 25 21 10
					</a>
				</div>
			</div>
			<div className="address-box mb-24">
				<div className="address-icon">
					<i className="fa fa-home"></i>
				</div>
				<div className="address-text">
					<span className="label">{t('contact_cta.email')}:</span>
					<a href="#">contact@bdtechnology.io</a>
				</div>
			</div>
			<div className="address-box">
				<div className="address-icon">
					<i className="fa fa-map-marker"></i>
				</div>
				<div className="address-text">
					<span className="label">{t('contact_cta.adress')}:</span>
					<div className="desc">Opal tower Office 309 , Business Bay Dubai</div>
				</div>
			</div>
		</div>
	);
};

export default ContactInfo;

import React from "react";
import { Link } from "react-router-dom";

const SingleTestimonial4 = (props) => {
  return (
    <div className={props.itemClass}>
      <div className="testi-content">
        <div className="images-wrap">
          <img src={props.authorImage} alt={props.Title} />
        </div>
        <div className="testi-information">
          <div className="testi-name" style={{ marginTop: "15px" }}>
            <Link to={`/service/${props.serviceLink}`}>{props.Title}</Link>
          </div>
          <span className="testi-title">{props.Designation}</span>
        </div>
      </div>
      <div className="item-content-basic">
        <div className="desc">{props.Description}</div>
      </div>
    </div>
  );
};

export default SingleTestimonial4;

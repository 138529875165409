import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";

// Service Image Icons
import roundImg from "../../assets/img/about/round.png";
// import aboutImg from "../../assets/img/about/about1.png";
import aboutImg from "../../assets/img/about/about.png";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      id="rs-about"
      className="rs-about style3 pt-120 pb-120 md-pt-74 md-pb-80"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <SectionTitle
              sectionClass="sec-title2 mb-30"
              subtitleClass="sub-text"
              subtitle={t('about_subtitle')}
              titleClass=" title title3 pb-20"
              title={t('about_title')}
              descClass="desc desc2"
              description={t('about_description')}
            />
            <div className="btn-part mt-40">
              <Link className="readon discover more" to="/about">
                {t('about_btn')}
              </Link>
            </div>
          </div>
          <div className="col-lg-7 md-pt-50">
            <div className="rs-animation-image">
              <div className="pattern-img">
                <img src={aboutImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

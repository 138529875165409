import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ServiceDetailsContent from "./ServiceDetailsContent";
import EmploiDetails from "./EmploiDetails";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/joboffer/backgroundjoboffer.png";

const SoftwareDevelopment = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="Carrière"
        activeMenu="/software-development"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Offres d'emploi"
        pageCategory="Carrière"
        pageName="Offres d'emploi"
      />
      {/* breadcrumb-area-end */}
      <EmploiDetails />
      <SearchModal />
    </React.Fragment>
  );
};

export default SoftwareDevelopment;

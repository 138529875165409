import React from "react";
import OwlCarousel from "react-owl-carousel2";

import brand1 from "../../assets/img/brand/style3/brand1.png";
import brand2 from "../../assets/img/brand/style3/brand2.png";
import brand3 from "../../assets/img/brand/style3/brand3.png";
import brand4 from "../../assets/img/brand/style3/brand4.png";
import brand5 from "../../assets/img/brand/style3/brand5.png";

const BrandHomeFive = () => {
  const options = {
    items: 5,
    nav: false,
    dots: false,
    rewind: false,
    autoplay: true,
    stagePadding: 0,
    margin: 30,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1500: {
        items: 5,
      },
    },
  };

  return (
    <div className="rs-partner style2 pt-50 pb-50">
      <div className="container">
        <OwlCarousel options={options}>
          <div className="partner-item">
            <div className="logo-img">
              <a href="#">
                <img src={brand1} alt="" />
              </a>
            </div>
          </div>
          <div className="partner-item">
            <div className="logo-img">
              <a href="#">
                <img src={brand2} alt="" />
              </a>
            </div>
          </div>
          <div className="partner-item">
            <div className="logo-img">
              <a href="#">
                <img src={brand3} alt="" />
              </a>
            </div>
          </div>
          <div className="partner-item">
            <div className="logo-img">
              <a href="#">
                <img src={brand4} alt="" />
              </a>
            </div>
          </div>
          <div className="partner-item">
            <div className="logo-img">
              <a href="#">
                <img src={brand5} alt="" />
              </a>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default BrandHomeFive;

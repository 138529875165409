import React from "react";
import BannerHomeSeven from "../../components/Banner/BannerHomeSeven";
import About from "./AboutSection";
import Blog from "./BlogSection";
import Brand from "../../components/Common/Brand";
import CTA from "./CtaSection";
import Process from "./ProcessSection";
import Project from "./ProjectSection";
import ProgressBar from "./ProgressBarSection";
import Pricing from "./PricingSection";
import Service from "./ServiceSection";
import ServiceTwo from "./ServiceSectionTwo";
import ServiceHomeSection from "./ServiceHomeSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import Technology from "./TechnologySection";
import HomeContact from "./HomeContact";

const HomeSevenMain = () => {
  return (
    <React.Fragment>
      {/* Testimonial-start */}
      <ServiceHomeSection />
      {/* Testimonial-end */}
      {/* About-area-start */}
      <About />
      {/* About-area-end */}
      {/* Technology-start */}
      <Technology />
      {/* Technology-end */}
      {/* blog-area-start */}
      {/* <Blog /> */}
      {/* blog-area-end */}
      {/* ContactMain Section Start */}
      <HomeContact />
      {/* ContactMain Section End */}
      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeSevenMain;

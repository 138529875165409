import React from "react";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";
import ScrollToTop from "../../components/Common/ScrollTop";

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const ContactMain = () => {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {/* Contact Section Start */}
      <div id="contactForm" className="rs-contact pt-120 md-pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 md-mb-60">
              <ContactInfo />
            </div>
            <div className="col-lg-8 pl-70 md-pl-14">
              <div className="contact-widget">
                <SectionTitle
                  sectionClass="sec-title2 mb-40"
                  subtitleClass="sub-text contact mb-14"
                  subtitle={t('contact_header.subtitle')}
                  titleClass="title testi-title"
                  title={t('contact_header.title')}
                />

                {/* Contact Form */}
                <ContactForm />
                {/* Contact Form */}
              </div>
            </div>
          </div>
        </div>

        <div className="map-canvas pt-120 md-pt-80">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4888106690864!2d55.271289515009315!3d25.186733033901035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69fde4a356f5%3A0x90c323502027a8c1!2sOpal%20Tower!5e0!3m2!1sen!2sdz!4v1674746612997!5m2!1sen!2sdz"></iframe>
        </div>

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;

import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';
import CloudDetails from './CloudDetails';

// Breadcrumbs Image
import cloudBg from '../../assets/img/bg/services/cloud.png';
import { useTranslation } from 'react-i18next';

const AnalyticSolutions = () => {
	const { t, i18n } = useTranslation();
	return (
		<React.Fragment>
			<Header
				parentMenu="service"
				secondParentMenu="services"
				activeMenu="/cloud-solution"
			/>
			{/* breadcrumb-area-start */}
			<SiteBreadcrumb
				breadcrumbsImg={cloudBg}
				pageTitle={t('services.cloud.title')}
				pageCategory="Services"
				pageName={t('services.cloud.title')}
			/>
			{/* breadcrumb-area-end */}
			<CloudDetails/>
			<SearchModal/>
		</React.Fragment>
	);
};

export default AnalyticSolutions;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TopHeaderStyleTwo from "./TopBarStyleTwo";
import RSMobileMenu from "./RSMobileMenu";
import MenuItems from "./MenuItems";

import Logo from "../../../assets/img/logo/logo.png";
import edLogo from "../../../assets/img/logo/edtechlogo.png";
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
const HeaderStyleSix = (props) => {
  const { parentMenu, secondParentMenu, activeMenu } = props;
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const searchModalAdd = () => {
    document.body.classList.add("modal-open");
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
      >
        {children}
      </a>
  ));

  return (
    <React.Fragment>
      <div className="full-width-header">
        <header id="rs-header" className="rs-header style2">
          {/* <TopHeaderStyleTwo /> */}
          <div
            className={
              isVisible
                ? "menu-area menu-sticky sticky"
                : "menu-area menu-sticky"
            }
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="logo-part hidden-md">
                    <Link to="/">
                      <img src={edLogo} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-9 text-right md-text-left">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <div className="mobile-menu md-display-block">
                        <Link to="/" className="logo">
                          <img src={edLogo} alt="logo" />
                        </Link>
                        <Link
                          to="#"
                          className="rs-menu-toggle primary"
                          onClick={() => {
                            setMenuOpen(!menuOpen);
                          }}
                        >
                          <i className="fa fa-bars"></i>
                        </Link>
                      </div>
                      <nav className="rs-menu lg-pr-50 md-pr-0 hidden-md">
                        <ul className="nav-menu">
                          <MenuItems
                            parentMenu={parentMenu}
                            secondParentMenu={secondParentMenu}
                            activeMenu={activeMenu}
                          />
                        </ul>
                      </nav>
                    </div>
                    <div className="expand-btn-inner search-icon hidden-md">
                      <ul>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                              <i className="fa fa-globe"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => i18n.changeLanguage('fr')} href="#">FR</Dropdown.Item>
                              <Dropdown.Item onClick={() => i18n.changeLanguage('en')}  href="#">EN</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="quote-btn hidden-lg"
                            href="#contactForm"
                          >
                            {t('menuItems.contact_us')}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}
          ></div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default HeaderStyleSix;

import React from "react";
import { Link } from "react-router-dom";
import BrandHomeFive from "./BrandSection";
import SectionTitle from "../../components/Common/SectionTitle";
import ScrollToTop from "../../components/Common/ScrollTop";

// Service Image
import aboutImg1 from "../../assets/img/service/style12/audit.png";
import { useTranslation } from 'react-i18next';

const ServiceDetailsContent = () => {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle={t('services.audit.title')}
                titleClass="title"
                title={t('services.audit.title')}
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4"
                descClass="desc desc-big"
                description=""
                secondDescClass="desc"
                secondDescription={
                  <React.Fragment>
                    <p>
                      {t('services.audit.text.part_1')}
                    </p>
                    <p>
                      {t('services.audit.text.part_2')}
                    </p>
                    <p>
                      {t('services.audit.text.part_3')}
                    </p>
                  </React.Fragment>
                }
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact">
                  <a className="readon learn-more">{t('contact_header.subtitle')}</a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="images">
                <img style={{ borderRadius: "50%" }} src={aboutImg1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* brand-area-start */}
      <BrandHomeFive />
      {/* brand-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default ServiceDetailsContent;

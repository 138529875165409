import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MenuItems = (props) => {
	const { parentMenu, secondParentMenu, activeMenu } = props;
	const { t, i18n } = useTranslation();
	return (
		<React.Fragment>
			<li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
				<Link to="/" as="/">
					{t('menuItems.home')}
				</Link>
			</li>
			<li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
				<Link
					to="/about"
					className={activeMenu === '/about' ? 'active-menu' : ''}
				>
					{t('menuItems.about')}
				</Link>
			</li>
			<li
				className={
					parentMenu === 'service'
						? 'menu-item-has-children current-menu-item'
						: 'menu-item-has-children'
				}
			>
				<Link href="#" as="#">
					{t('menuItems.services.title')}
				</Link>
				<ul className="sub-menu">
					<li className="menu-item-has-children">
						<Link
							to="/service/reseau-solution"
							className={secondParentMenu === 'Audit' ? 'active-menu' : ''}
						>
							{t('menuItems.services.network')}
						</Link>
					</li>
					<li className="menu-item-has-children">
						<Link
							to="/service/security-solution"
							className={
								secondParentMenu === 'Transformation' ? 'active-menu' : ''
							}
						>
							{t('menuItems.services.security')}
						</Link>
					</li>
					<li className="menu-item-has-children">
						<Link
							to="/service/data-solution"
							className={secondParentMenu === 'protection' ? 'active-menu' : ''}
						>
							{t('menuItems.services.audit')}
						</Link>
					</li>
					<li className="menu-item-has-children">
						<Link
							to="/service/cloud-solution"
							className={secondParentMenu === 'services' ? 'active-menu' : ''}
						>
							{t('menuItems.services.cloud')}
						</Link>
					</li>
				</ul>
			</li>
			<li
				className={
					parentMenu === 'offresemploi'
						? 'menu-item-has-children current-menu-item'
						: ''
				}
			>
				<a className="quote-btn hidden-lg" href="/#technology">
					{t('menuItems.partners')}
				</a>
			</li>
			<li
				className={
					parentMenu === 'offresemploi'
						? 'menu-item-has-children current-menu-item'
						: ''
				}
			>
				<Link to="/offres-emploi">{t('menuItems.career')}</Link>
			</li>
			<li
				className={
					parentMenu === 'blog'
						? 'menu-item-has-children current-menu-item'
						: 'menu-item-has-children'
				}
			>
				<Link to="/">{t('menuItems.blog')}</Link>
			</li>
			<li
				className={
					parentMenu === 'blog'
						? 'menu-item-has-children current-menu-item'
						: ''
				}
			></li>
		</React.Fragment>
	);
};

export default MenuItems;

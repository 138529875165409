import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";

import SectionTitle from "../../components/Common/SectionTitle";
import SingleTeam from "../../components/Team/SingleTeam";
import SingleProcess from "../../components/Process/SingleProcess";
import SinglePricing from "../../components/Pricing/SinglePricing";
import VideoStyleThree from "../../components/Video/VideoStyleThree";

// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage2 from "../../assets/img/process/2.png";
import processImage3 from "../../assets/img/process/3.png";
import processImage4 from "../../assets/img/process/4.png";

// Working Process Images
import teamImg1 from "../../assets/img/team/1.jpg";
import teamImg2 from "../../assets/img/team/2.jpg";
import teamImg3 from "../../assets/img/team/3.jpg";
import teamImg4 from "../../assets/img/team/4.jpg";
import teamImg5 from "../../assets/img/team/5.jpg";
import teamImg6 from "../../assets/img/team/6.jpg";
import teamImg7 from "../../assets/img/team/7.jpg";

import aboutImg from "../../assets/img/about/about.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Pricing Image
import silverImg from "../../assets/img/pricing/1.png";
import goldImg from "../../assets/img/pricing/2.png";
import platinumImg from "../../assets/img/pricing/3.png";

import effectImg1 from "../../assets/img/about/dotted-3.png";
import effectImg2 from "../../assets/img/about/shape3.png";
import { useTranslation } from 'react-i18next';

const AboutMain = () => {
  const { t, i18n } = useTranslation();
  const options = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        dots: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
        dots: true,
      },
      992: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      {/* about-area-start */}
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img style={{ borderRadius: "40%" }} src={aboutImg} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle={t('about_subtitle')}
                  titleClass="title pb-36"
                  title={t('about_title')}
                  descClass="desc pb-34"
                  secondDescClass="margin-0 pb-16"
                  secondDescription={t('about_text')}
                />
                {/* Section Title End */}
                <div className="btn-part">
                  <Link className="readon learn-more" to="/contact">
                    {t('contact_header.subtitle')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
      </div>
      {/* about-area-end */}
    </React.Fragment>
  );
};

export default AboutMain;

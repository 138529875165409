import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import DataDetails from './DataDetails';

// Breadcrumbs Image
import dataBg from '../../assets/img/bg/services/data.png';
import { useTranslation } from 'react-i18next';

const AnalyticSolutions = () => {
	const { t, i18n } = useTranslation();
	return (
		<React.Fragment>
			<Header
				parentMenu="service"
				secondParentMenu="services"
				activeMenu="/data-solution"
			/>
			{/* breadcrumb-area-start */}
			<SiteBreadcrumb
				breadcrumbsImg={dataBg}
				pageTitle={t('services.audit.title')}
				pageCategory="Services"
				pageName={t('services.audit.title')}
			/>
			{/* breadcrumb-area-end */}
			<DataDetails/>
			<SearchModal/>
		</React.Fragment>
	);
};

export default AnalyticSolutions;

import React from "react";
import { Link } from "react-router-dom";
import Brand from "../../components/Common/Brand";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleService from "../../components/Service/SingleService";
import SinglePricing from "../../components/Pricing/SinglePricing";
import ProgressBar from "../../components/Elements/Progress/ProgressBar";
import ScrollToTop from "../../components/Common/ScrollTop";

// Service Image
import serviceIcon1 from "../../assets/img/service/style7/1.png";
import serviceIcon2 from "../../assets/img/service/style7/2.png";
import serviceIcon3 from "../../assets/img/service/style7/3.png";

import aboutImg1 from "../../assets/img/joboffer/joboffer.png";
// Pricing Image
import silverImg from "../../assets/img/pricing/1.png";
import goldImg from "../../assets/img/pricing/2.png";
import platinumImg from "../../assets/img/pricing/3.png";

// Choose Us Image
import whyChooseUsImg from "../../assets/img/choose/3.png";

const EmploiDetails = () => {
  return (
    <React.Fragment>
      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <div>
                <h3>ARCHITECTE RÉSEAUX F/H</h3>
                <div className="mb-3">
                  <h4>Missions</h4>
                  <ul className="features-list mb-5">
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Effectuer / contribuer à l’architecture technique de
                        projets complexes Réseaux-Sécurité{" "}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Configurer de A à Z les solutions techniques de son
                        périmètre et / ou être en mesure de challenger
                        l’intégrateur{" "}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Support technique dans le cadre du maintien en condition
                        opérationnelle ou des projets (support N3 aux équipes
                        Opérations et aux équipes Projets){" "}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Rédiger des documents techniques (procédures, dossiers
                        de mise en œuvre des services, dossiers de présentation
                        sur les produits){" "}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h4>Exemples de projets type :</h4>
                  <ul className="features-list mb-5">
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Design Architecture Data Center</span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Migration Data Center</span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Coordination technique d’un déploiement Wifi</span>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h4>Evolutions possibles du poste :</h4>
                  <ul className="features-list mb-5">
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Expert Réseaux </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Expert Sécurité </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Architecte </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Chef de projet technique </span>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h4>Nous recherchons des talents :</h4>
                  <ul className="features-list mb-5">
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>Dynamiques, curieux et rigoureux </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Ayant un diplôme d’études supérieures ou une expérience
                        équivalente valorisée par des certifications d’un niveau
                        intermédiaire{" "}
                        <strong>(ex. CCNP) à expert (ex. CCIE)</strong>
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Ayant idéalement une expérience de 5 ans min sur un
                        poste similaire{" "}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Ayant une bonne capacité à gérer les priorités
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Ayant un français parlé / écrit irréprochable et un
                        anglais professionnel de qualité
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h4>Compétences / aptitudes requises:</h4>
                  <ul className="features-list mb-5">
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Maîtriser une ou plusieurs technologies Sécurité du
                        marché (ex : F5, Check Point VSX / Palo Alto / Cisco ASA
                        VPN SSL / Fortinet / Blue Coat){" "}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Ayant un diplôme d’études supérieures ou une expérience
                        équivalente valorisée par des certifications d’un niveau
                        intermédiaire{" "}
                        <strong>(ex. CCNP) à expert (ex. CCIE)</strong>
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Maitriser une ou plusieurs technologies Réseaux
                        DataCenter et / ou LAN du marché (Cisco, Arista, HP,
                        Meraki, )
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-circle mr-5"></i>
                      <span>
                        Avoir une compétence avérée ou aptitude pour monter en
                        compétence sur les langages liés à l’automatisation
                        (Python principalement)
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact">
                  <a className="readon learn-more">Nous contacter</a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="images">
                <img style={{ borderRadius: "50%" }} src={aboutImg1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default EmploiDetails;

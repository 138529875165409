import React, { useState, Suspense } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial4 from '../../components/Testimonial/SingleTestimonial4';
import i18n from 'i18next';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
// Testimonial Images

// Service Image Icons
import serviceIcon1 from '../../assets/img/service/style11/1.png';
import serviceIcon2 from '../../assets/img/service/style11/2.png';
import serviceIcon3 from '../../assets/img/service/style11/3.png';
import serviceIcon4 from '../../assets/img/service/style11/4.png';
import Particles from 'react-tsparticles';
import '../../assets/style.css';

const Testimonial = () => {
	const { t, i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};


	const options = {
		items: 3,
		nav: true,
		dots: false,
		margin: 50,
		rewind: false,
		autoplay: false,
		stagePadding: 30,
		navText: [
			'<i class=\'fa fa-angle-left\'></i>',
			'<i class=\'fa fa-angle-right\'></i>'
		],
		loop: true,
		center: true,
		responsive: {
			0: {
				stagePadding: 0,
				items: 1,
				nav: false
			},
			768: {
				items: 1,
				stagePadding: 0,
				nav: false
			},
			992: {
				items: 1,
				stagePadding: 0,
				nav: true
			},
			1200: {
				items: 1,
				stagePadding: 0
			},
			1500: {
				items: 1,
				stagePadding: 0
			}
		}
	};

	return (
			<React.Fragment>
				<div className="rs-testimonial">
					<div className="container">
						<div className="testi-effects-layer" id="particles-js">
							<Particles
								options={{
									fullScreen: { enable: false },
									fps_limit: 60,
									interactivity: {
										detectsOn: 'canvas',
										events: {
											onClick: { enable: true, mode: 'push' },
											onHover: { enable: true, mode: 'repulse' },
											resize: true
										},
										modes: {
											push: { particles_nb: 4 },
											repulse: { distance: 200, duration: 0.4 }
										}
									},
									particles: {
										color: { value: '#ffffff' },
										links: {
											color: '#ffffff',
											distance: 150,
											enable: true,
											opacity: 0.4,
											width: 1
										},
										move: {
											bounce: false,
											direction: 'none',
											enable: true,
											outMode: 'out',
											random: false,
											speed: 2,
											straight: false
										},
										number: { density: { enable: true, area: 800 }, value: 80 },
										opacity: { value: 0.5 },
										shape: { type: 'circle' },
										size: { random: true, value: 5 }
									},
									detectRetina: true
								}}
								className="App-particles__container"
							/>
							<div className="row align-items-center">
								<div className="col-lg-5 mb-60">
									<SectionTitle
										sectionClass="sec-title mb-60 md-mb-30"
										subtitleClass="sub-text new"
										subtitle={t('header_subtitle')}
										titleClass="title title4 white-color pb-20"
										title={t('header_title')}
									/>
								</div>
								<div className="col-lg-7">
									<div className="rs-testimonial style4">
										<OwlCarousel options={options}>
											<SingleTestimonial4
												itemClass="testi-item"
												authorImage={serviceIcon1}
												Title={t('security_title')}
												Description={t('security_description')}
												serviceLink="security-solution"
											/>
											<SingleTestimonial4
												itemClass="testi-item"
												authorImage={serviceIcon4}
												Title={t('network_title')}
												Description={t('network_description')}
												serviceLink="reseau-solution"
											/>
											<SingleTestimonial4
												itemClass="testi-item"
												authorImage={serviceIcon3}
												Title={t('cloud_title')}
												Description={t('cloud_description')}
												serviceLink="cloud-solution"
											/>
											<SingleTestimonial4
												itemClass="testi-item"
												authorImage={serviceIcon2}
												Title={t('audit_title')}
												Description={t('audit_description')}
												serviceLink="data-solution"
											/>
										</OwlCarousel>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
	);
};

export default Testimonial;

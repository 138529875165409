import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {
  const [home, setHome] = useState(false);
  const [homeMultipage, setHomeMultipage] = useState(false);
  const [homeOnepage, setHomeOnepage] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setServices] = useState(false);
  const [blog, setBlog] = useState(false);
  const [pages, setPages] = useState(false);
  const [servicePages, setServicePages] = useState(false);
  const [caseStudies, setCaseStudies] = useState(false);
  const [shopPages, setShopPages] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "homeMultipage") {
      setHome(true);
      setHomeMultipage(!homeMultipage);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "homeOnepage") {
      setHome(true);
      setHomeMultipage(false);
      setHomeOnepage(!homeOnepage);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "about") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(!about);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "services") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(!services);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "blog") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(!blog);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "pages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(!pages);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "servicePages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(!servicePages);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "caseStudies") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(false);
      setCaseStudies(!caseStudies);
      setShopPages(false);
    } else if (menu === "shopPages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(!shopPages);
    }
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li
              className={
                home
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                onClick={() => {
                  openMobileMenu("home");
                }}
                className={activeMenu === "/" ? "active-menu" : ""}
                to="/"
                as="/"
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={activeMenu === "/about" ? "active-menu" : ""}
              >
                À propos
              </Link>
            </li>
            <li
              className={
                pages
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("pages");
                }}
              >
                Services
              </Link>
              <ul className={pages ? "sub-menu current-menu" : "sub-menu"}>
                <li
                  className={
                    servicePages
                      ? "menu-item-has-children current-menu-item"
                      : ""
                  }
                >
                  <Link
                    to="/service/data-solution"
                    onClick={() => {
                      openMobileMenu("servicePages");
                    }}
                  >
                    Audit &amp; Etudes 
                  </Link>
                  <ul
                    className={
                      servicePages ? "sub-menu current-menu" : "sub-menu"
                    }
                  ></ul>
                </li>
                <li
                  className={
                    servicePages
                      ? "menu-item-has-children current-menu-item"
                      : ""
                  }
                >
                  <Link
                    to="/service/cloud-solution"
                    onClick={() => {
                      openMobileMenu("servicePages");
                    }}
                  >
                    Cloud Computing
                  </Link>
                </li>
                <li
                  className={
                    servicePages
                      ? "menu-item-has-children current-menu-item"
                      : ""
                  }
                >
                  <Link
                    to="/service/security-solution"
                    onClick={() => {
                      openMobileMenu("servicePages");
                    }}
                  >
                    Cyber Sécurité
                  </Link>
                </li>
                <li
                  className={
                    servicePages
                      ? "menu-item-has-children current-menu-item"
                      : ""
                  }
                >
                  <Link
                    to="/service/reseau-solution"
                    onClick={() => {
                      openMobileMenu("servicePages");
                    }}
                  >
                    Réseau
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                blog
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("blog");
                }}
              >
                Blog
              </Link>
              <ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/blog"
                    className={activeMenu === "/blog" ? "active-menu" : ""}
                  >
                    Blog
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#technology"
                className={activeMenu === "/" ? "active-menu" : ""}
              >
                Partenaires
              </a>
            </li>
            <li>
              <Link
                to="/offres-emploi"
                className={activeMenu === "/" ? "active-menu" : ""}
              >
                Carrière
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
